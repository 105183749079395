<template>
  <div class="row">
    <div class="col-12">
      <b-overlay :show="showLoading">
        <b-card>
          <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
            <h4 class="col-12 col-xl-4 mb-2 mb-xl-0 font-weight-bold">
              優惠券使用紀錄
            </h4>
            <div class="col-12 col-xl-8 d-flex flex-xl-row align-items-center align-items-xl-center justify-content-end">
              <b-button
                class="flex-shrink-0 mb-0 mr-2"
                variant="primary"
                @click="handleExport"
              ><i class="mdi mdi-download"></i>匯出</b-button>
              <template v-if="upperCustomer.id">
                <b-button variant="primary" v-b-modal.modal-add>
                  手動給券
                </b-button>
              </template>
              <template v-if="showFilter">
                <b-input-group class="col-12 d-flex">
                  <b-select
                    class="mr-3"
                    :options="statusOptions"
                    v-model="query.status_for_admin"
                    @change="handlePageReset"
                  ></b-select>
                  <b-select
                    class="mr-3"
                    :options="branchOptions"
                    v-model="query.branch_id"
                    @change="handlePageReset"
                  ></b-select>
                  <div
                    class="
                    d-flex
                    flex-column flex-xl-row mr-2
                    align-items-start align-items-xl-center
                  "
                  >
                    <span> 區間：</span>
                    <datepicker
                      placeholder="Select Date"
                      bootstrap-styling
                      format="yyyy-MM-dd"
                      :value="query.after"
                      @selected="handleRedeemAfterSelected"
                    ></datepicker>
                    <span class="mx-2">~</span>
                    <datepicker
                      placeholder="Select Date"
                      bootstrap-styling
                      format="yyyy-MM-dd"
                      :value="query.before"
                      @selected="handleRedeemBeforeSelected"
                    ></datepicker>
                  </div>
                  <b-form-input
                    v-model="query.keyword_v2"
                    placeholder="搜尋會員名稱、手機、外部編碼"
                    v-on:keyup.enter="handlePageReset"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button @click="handlePageReset"
                    ><i class="fa fa-search"></i
                    ></b-button>
                  </b-input-group-append>
                </b-input-group>
              </template>
            </div>
          </div>

          <b-card-text>
            <div class="row">
              <div class="col-12">
                <b-overlay rounded="sm">
                  <b-table
                    striped
                    hover
                    responsive
                    :items="redeemCodes"
                    :fields="filteredFields"
                    @sort-changed="handleSort"
                  >
                  </b-table>
                </b-overlay>
              </div>
            </div>
            <CustomPagination
              :currentPage="query.page"
              :totalRows="totalRows"
              :perPage="query.per_page"
              @page-change="handlePageChange"
              @per-page-change="handlePerPageChange"
            />
          </b-card-text>
        </b-card>
      </b-overlay>
    </div>

    <b-modal id="modal-add" title="手動給券">
      <b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="sm"
        label="優惠券"
      >
        <vue-typeahead-bootstrap
          :data="filteredRedeem"
          v-model="redeemKeyWord"
          @hit="selectedRedeemCodeBundle = $event"
          :showOnFocus="true"
          :serializer="(item) => item.name"
          placeholder="請輸入獎項名稱"
        >
          <template slot="suggestion" slot-scope="{ data }">
            <div class="d-flex align-items-center">
              <b-badge variant="outline-dark mr-2">獎項種類</b-badge>
              <span class="font-weight-bold">{{ data.name }}</span>
            </div>
            <div class="small py-2">
              {{ data.description }}
            </div>
            <div class="small font-weight-bold">
              <span>{{ data.start_at }}</span>
              <span class="mx-2">-</span>
              <span>{{ data.end_at }}</span>
            </div>
          </template>
        </vue-typeahead-bootstrap>
      </b-form-group>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-danger"
          size="sm"
          class="float-right"
          @click="cancel()"
        >
          關閉
        </b-button>
        <b-button
          size="sm"
          variant="outline-primary"
          @click="assignRedeemCodeByBundle"
        >
          確定
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import redeemCodeApi from "@/apis/redeem-code";
import redeemCodeBundleApi from "@/apis/redeemCodeBundle";
import { startOfDay, endOfDay, format} from "date-fns";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";
import {mapGetters} from 'vuex';
import Datepicker from "vuejs-datepicker";
import { paginationMixin } from "@/mixins/pagination";

export default {
  mixins: [paginationMixin],
  components: {
    CustomPagination,
    VueTypeaheadBootstrap,
    Datepicker,
  },
  props: {
    showFilter: {
      type: Boolean,
      default: true,
    },
    excludeFields: {
      type: Array,
      default: () => [],
    },
    upperCustomer: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const baseFields = [
      {
        key: "redeem_code_bundle.name",
        label: "優惠券名稱",
      },
      {
        key: "code",
        label: "序號",
      },
      {
        key: "customer",
        label: "會員名稱",
        formatter: (value) => {
          if (value && value.name) {
            return this.hiddenString(value.name, 20);
          }
          return "";
        },
      },
      {
        key: "branch.name",
        label: "分店名稱",
      },
      {
        key: "branch.branch_code",
        label: "分店代號",
      },
      {
        key: "belong_at",
        label: "指派時間",
        formatter: (value) => {
          return value
            ? format(new Date(value), "yyyy-MM-dd HH:mm:ss")
            : null;
        },
        sortable: true,
      },
      {
        key: "apply_at",
        label: "領取時間",
        formatter: (value) => {
          return value
            ? format(new Date(value), "yyyy-MM-dd HH:mm:ss")
            : null;
        },
        sortable: true,
      },
      {
        key: "redeem_at",
        label: "使用時間",
        formatter: (value) => {
          return value
            ? format(new Date(value), "yyyy-MM-dd HH:mm:ss")
            : null;
        },
        sortable: true,
      },
      {
        key: "is_enabled",
        label: "啟用/停用",
        formatter: (value) => {
          return value ? "啟用" : "停用";
        },
      },
      {
        key: "status",
        label: "使用狀況",
        formatter: (value, key, item) => {
          switch (true) {
            case !!item.redeem_at:
              return "已兌換";
            case !!item.apply_at:
              return "已領取";
            case !!item.belong_at:
              return "已歸戶";
            default:
              return "未領取";
          }
        },
      },
    ];
    return {
      showLoading: false,
      statusOptions: [
        { value: "above_belonged", text: "全部" },
        { value: "belonged", text: "未領取" },
        { value: "applied", text: "已領取" },
        { value: "redeemed", text: "已使用" },
      ],
      totalRows: 0,
      redeemCodes: [],
      canAssignRedeemCodesBundles: [],
      fields: baseFields,
      baseFields: baseFields,
      customers: [],
      selectedRedeemCodeBundle: null,
      redeemKeyWord: "",
      initialized: false,
      query: {
        page: 1,
        per_page: 10,
        sort_by: "redeem_at",
        order_by: "desc",
        keyword_v2: null,
        status_for_admin: "above_belonged",
        branch_id: null,
        after: null,
        before: null,
      },
    };
  },
  computed: {
    filteredFields() {
      return this.fields.filter(
        (field) => !this.excludeFields.includes(field.key)
      );
    },
    filteredRedeem() {
      return this.canAssignRedeemCodesBundles.filter((a) => {
        return a.name.indexOf(this.redeemKeyWord) >= 0;
      });
    },
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
    ...mapGetters('general', [
      'myBranches',
    ]),
    ...mapGetters({
      getModuleConfig: "dashboardModule/getConfig",
    }),
    branchOptions() {
      let options = this.myBranches
        .map(branch => {
          let branchName = `${branch.branch_code} ${branch.name}`
          return {
            value: branch.id,
            text: branchName
          }
        })
      options = [
        {
            value: null,
            text: '請選擇分店'
        },
        ...options
      ]
      return options
    },
  },
  created() {
    this.query = updateQueryFromRoute(
      this.$route,
      this.$store,
      this.query
    );
  },
  mounted() {
    this.fetchRedeemCodes();
    if (this.upperCustomer.merchant?.id) {
      this.fetchCanAssignRedeemCodes();
    }
    this.fetchFields();
    this.initialized = true;
  },
  watch: {
    upperCustomer(value) {
      if (value.id) {
        this.fetchCanAssignRedeemCodes();
      }
    },
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    "$route.query": {
      handler(newQuery) {
        if (!this.initialized) return;
        if (Object.keys(newQuery).length !== 0) {
          this.query = { ...newQuery };
        }
        this.fetchRedeemCodes();
      },
      deep: true,
    },
  },
  methods: {
    async fetchRedeemCodes() {
      this.showLoading = true;

      if (this.query.status_for_admin === 'belonged') {
        this.query.belong_after = this.query.after;
        this.query.belong_before = this.query.before;
      }

      if (this.query.status_for_admin === 'applied') {
        this.query.apply_after = this.query.after;
        this.query.apply_before = this.query.before;
      }

      if (this.query.status_for_admin === 'redeemed') {
        this.query.redeem_after = this.query.after;
        this.query.redeem_before = this.query.before;
      }

      let params = {
        ...this.query,
        // 若 upperCustomer.id 為 null，api 參數會被轉成 null 字串，所以這邊多一個判斷將 null 變成空字串
        customer_id: this.upperCustomer.id || "",
      };
      const response = await redeemCodeApi.getRedeemCodes(params);
      this.redeemCodes = response.data.data;
      this.totalRows = response.data.meta.total;
      this.showLoading = false;
    },
    async fetchCanAssignRedeemCodes() {
      this.showLoading = true;
      const response = await redeemCodeBundleApi.getCanAssignRedeemCodeBundles({
        merchant_id: this.upperCustomer.merchant.id,
      });
      this.canAssignRedeemCodesBundles = response.data.data;
      this.canAssignRedeemCodesBundles.forEach((item) => {
        item.start_at = format(new Date(item.start_at), "yyyy-MM-dd");
        item.end_at = format(new Date(item.end_at), "yyyy-MM-dd");
      });

      this.showLoading = false;
    },
    hiddenString(value, length) {
      value = value || "";
      const splittedValue = [...value];
      if (splittedValue.length <= length) {
        return value;
      }
      return splittedValue.slice(0, length).join("") + "...";
    },
    async assignRedeemCodeByBundle() {
      try {
        await redeemCodeBundleApi.assignRedeemCodeByBundle(
          this.selectedRedeemCodeBundle.id,
          { customer_id: this.upperCustomer.id }
        );
        this.$swal.fire({
          title: "新增成功",
          type: "success",
        });
      } catch (error) {
        this.$swal.fire({
          title: "新增失敗",
          type: "error",
        });
        console.log(error);
      } finally {
        this.$bvModal.hide("modal-add");
        this.fetchCanAssignRedeemCodes();
        this.selectedRedeemCodeBundle = null;
      }
    },
    handleSort(ctx) {
      this.query.sort_by = ctx.sortBy;
      this.query.order_by = ctx.sortDesc ? "desc" : "asc";
      this.handlePageReset();
    },
    handleRedeemAfterSelected(after) {
      this.query.after = format(startOfDay(after), 'yyyy-MM-dd HH:mm:ss');
      this.query.page = 1;
      this.handlePageReset();
    },
    handleRedeemBeforeSelected(before) {
      this.query.before = format(endOfDay(before), 'yyyy-MM-dd HH:mm:ss');
      this.query.page = 1;
      this.handlePageReset();
    },
    async handleExport() {
      if (this.query.status_for_admin === 'belonged') {
        this.query.belong_after = this.query.after;
        this.query.belong_before = this.query.before;
      }

      if (this.query.status_for_admin === 'applied') {
        this.query.apply_after = this.query.after;
        this.query.apply_before = this.query.before;
      }

      if (this.query.status_for_admin === 'redeemed') {
        this.query.redeem_after = this.query.after;
        this.query.redeem_before = this.query.before;
      }

      let params = {
        ...this.query,
        customer_id: this.upperCustomer.id || "",
      };
      try {
        this.showLoading = true;
        await redeemCodeApi.exportRedeemCodes(params).then(() => {
          this.$swal({
            title: '成功',
            text: "可至“我的主控台”查看紀錄及下載檔案",
            showCancelButton: true,
            cancelButtonText: '確認',
            type: 'success',
            confirmButtonText: '前往查看',
          }).then((result)=>{
            if (result.value) {
              this.$router.push({
                name: "MyConsoleDownloadFileList",
                params: { org_code: this.$route.params.org_code },
              });
            }
          });
        });
      } catch (e) {
        console.log(e)
      }
      this.showLoading = false
    },
    async fetchFields() {
      let normalizeProfileFields = await this.normalizeProfileFields();
      // 找到插入的位置
      const insertIndex = this.baseFields.findIndex(
        (field) => field.key === "branch.name"
      );

      // 創建新的欄位陣列，插入 normalizeProfileFields
      this.fields = [
        ...this.baseFields.slice(0, insertIndex), // 從開頭到插入點之前的欄位
        ...normalizeProfileFields,          // 插入 normalizeProfileFields
        ...this.baseFields.slice(insertIndex),   // 插入點之後的欄位
      ];
    },
    async normalizeProfileFields() {
      const customerList = this.getModuleConfig("customer", "reward.customer_list");

      const profileMappings = customerList?.[0]?.member_fields ?? [];

      if (profileMappings) {
        return profileMappings.map((mapping) => {
          if (mapping.matched_key.startsWith("extra.")) {
            const key = mapping.matched_key.split("extra.")[1];
            return {
              key: `profiles.${key}`,
              label: mapping.title,
            };
          } else {
            return {
              key: mapping.matched_key,
              label: mapping.title,
            };
          }
        });
      } else {
        return [];
      }
    },
  },
};
</script>
